<template>
  <div class="flex w-full flex-col space-y-4">
    <span v-if="!hideTitle" class="text-2xl font-bold" v-text="t('title')" />

    <span class="text-base" v-text="t('hint')" />

    <div v-if="errorMessage" class="relative h-12">
      <AuthErrorMessage :error="errorMessage" />
    </div>

    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      novalidate
      @submit="submit"
    >
      <FormKitMessages class="text-system-error mb-4" />

      <div class="space-y-4">
        <FormKit
          type="password"
          name="password"
          autocomplete="new-password"
          :placeholder="t('password')"
          validation="required:trim|contains_numeric|contains_uppercase|contains_lowercase|contains_alphanumeric|length:6,32"
          :validation-label="t('password')"
          :validation-messages="{
            required: t('requiredPassword'),
            contains_numeric: t('genericPasswordMessage'),
            contains_uppercase: t('genericPasswordMessage'),
            contains_lowercase: t('genericPasswordMessage'),
            contains_alphanumeric: t('genericPasswordMessage'),
            length: t('genericPasswordMessage'),
          }"
        />

        <FormKit
          type="password"
          name="password_confirm"
          autocomplete="new-password"
          :placeholder="t('newPasswordConfirmation')"
          validation="(200)required|confirm"
          validation-label="Password confirmation"
          :validation-messages="{
            required: t('requiredPasswordConfirm'),
            confirm: t('confirmPasswordConfirm'),
          }"
        />

        <div class="mt-4 flex flex-col space-y-1 md:items-end">
          <Button
            type="submit"
            :theme="BUTTON_THEME.PRIMARY"
            :text="t('send')"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
interface Props {
  email: string
  token: string
  hideTitle?: boolean
}

const { email, token } = withDefaults(defineProps<Props>(), {
  hideTitle: false,
})

const { t } = useI18n()
const router = useRouter()
const { changeState } = useAuthState()

const formData = ref()
const errorMessage = ref()

async function submit() {
  const { data, error } = await useAsyncGql('AccountResetPassword', {
    email,
    token,
    password: formData.value.password,
  })

  if (data.value?.accountResetPassword?.message) {
    router.replace({
      query: {},
    })

    changeState(USER_AUTH_STATE.RESET_PASSWORD_SUCCESS)
  }

  if (error.value) {
    errorMessage.value =
      error.value?.cause?.gqlErrors?.[0]?.extensions?.message ??
      error.value?.cause?.gqlErrors?.[0]?.message
  }
}

defineOptions({
  name: 'AuthResetPasswordNew',
})
</script>

<i18n>
de:
  password: "Passwort"
  title: "Neues Passwort festlegen"
  hint: "Das Passwort muss aus mindestens 6 Zeichen bestehen und mindestens einen Klein- und Großbuchstaben, sowie eine Zahl enthalten."
  newPassword: "Bitte gib dein neues Passwort ein"
  newPasswordConfirmation: "Passwort bestätigen"
  send: "Neues Passwort speichern"
  requiredPassword: 'Bitte Passwort eingeben.'
  genericPasswordMessage: 'Das Passwort entspricht nicht unseren Richtlinien (siehe oben).'
  requiredPasswordConfirm: 'Bitte Passwort wiederholen.'
  confirmPasswordConfirm: 'Passwörter stimmen nicht überein.'
es:
  password: "Contraseña"
  title: "Establecer nueva contraseña"
  hint: "La contraseña debe tener al menos 6 caracteres y contener al menos una letra mayúscula y minúscula, así como un número."
  newPassword: "Por favor, introduce tu nueva contraseña"
  newPasswordConfirmation: "Confirmar contraseña"
  send: "Guardar nueva contraseña"
  requiredPassword: 'Por favor, introduce una contraseña.'
  genericPasswordMessage: 'La contraseña no cumple con nuestros criterios (ver arriba).'
  requiredPasswordConfirm: 'Por favor, repite la contraseña.'
  confirmPasswordConfirm: 'Las contraseñas no coinciden.'
</i18n>
